<template>
    <div>
        <nav-bar title="浏览记录" url="/sale"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">
                    用户浏览记录
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>

            <van-list v-model="loading" :finished="finish" finished-text="没有更多了" @load="get_list()">
                <van-cell-group class="group-user-list">
                    <van-cell v-for="(item,index) in list" :key="index" :icon="item.headimgurl"
                        :title="item.nickname + '[' + item.create_time +']'" :value="'第'+item.view_number  + '次访问'"
                        :label="'总办理数:' + item.handle_number + ',   在我办理数:' + item.sale_number + '。'">
                    </van-cell>
                </van-cell-group>
            </van-list>


            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>

    </div>
</template>

<script>
    import {
        sale_view,
    } from '@/api/sale.js';
    export default {
        name: 'sale_view',
        data() {
            return {
                list: [],
                finish: false,
                loading: false,
            }
        },
        methods: {
            get_list() {
                this.loading = true;
                sale_view({
                    start: this.list.length,
                    count: 20,
                }).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.list = this.list.concat(res.data.info);
                        if (res.data.count < 20) {
                            this.finish = true; //加载完成
                        }
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .van-icon__image {
        height: 50px;
        width: 50px;
    }

    .van-cell__title {
        flex: 2;
    }

    .van-cell__value {
        width: 80px;
    }
    
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }
</style>